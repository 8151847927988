// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-abraj-building-jsx": () => import("./../../../src/pages/abraj-building.jsx" /* webpackChunkName: "component---src-pages-abraj-building-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-future-projects-jsx": () => import("./../../../src/pages/future-projects.jsx" /* webpackChunkName: "component---src-pages-future-projects-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jifnat-project-jsx": () => import("./../../../src/pages/jifnat-project.jsx" /* webpackChunkName: "component---src-pages-jifnat-project-jsx" */),
  "component---src-pages-our-projects-jsx": () => import("./../../../src/pages/our-projects.jsx" /* webpackChunkName: "component---src-pages-our-projects-jsx" */)
}

